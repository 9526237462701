html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
	min-height: 100vh;
}

ol,


blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

@font-face {
	font-family: Inter;
	src: url(../public/fonts/Inter-Regular.ttf);
}

.mainBlock {
	display: flex;
	flex-direction: row;
	min-height: calc(100vh - 71px);
}

.App {
	flex: 1;
	min-height: 100vh;
}

#root {
	flex: 1;
	min-height: 100vh;
}

table {
	width: 100%;
	font-family: Inter;
}

thead {
	font-weight: 600;
	font-size: 16px;
}

thead th {
	padding: 8px;
}

th {
	border: 1px solid black;
}

.RouteWrapper {
	display: flex;
	justify-content: center;
	flex: 1;
}

button {
	border: none;
}

button:hover {
	cursor: pointer;
	opacity: .9;
}

.LoginBlock {
	width: 100vw;
	height: 100vh;
	align-items: center;
	justify-content: center;
	display: flex;
}

.LoginWrapper {
	display: flex;
	flex-direction: column;
	color: #000;
	text-align: center;
	font-family: Inter;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	align-items: center;
	justify-content: center;
}

.LoginWrapper input {
	width: 398px;
	height: 39px;
	border-radius: 7px;
	border: 0px solid #000;
	background: #F2F2F2;
	margin-top: 6px;
	margin-bottom: 18px;
	text-align: center;
	font-family: Inter;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.LoginTitle {
	color: #000;
	text-align: center;
	font-family: Inter;
	font-size: 32px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 46px;
	display: flex;
}

.LoginButton {
	width: 300px;
	height: 38px;
	border-radius: 27px;
	border: 2px solid #000;
	background: #000;
	color: #FFF;
	text-align: center;
	font-family: Inter;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-top: 50px;
}
.Path{
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	margin-left: 8px;
	justify-content: row;
	display: flex;
	margin: 8px;
}
.PathButton{
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	background: none;
}
.buttonTextWraper button{
	height: 24px;
	margin: 4px;
	border-radius: 2px;
}
.buttonTextWraper{
	flex-direction: row;
	justify-content: center;
}
.HtmlWrapper{
	border:1px solid black;
	margin-top: 20px;
	width: 100%;
	height: 400px;
}


.wrapper{
	border: 1px solid grey;
  }
  

.Editor a{
	color: blue;
	text-decoration: underline;
}
.Editor{
	font-weight: 400;
	line-height: normal;
}
.Editor b{
	font-weight: 600;
}
.Editor i{
	font-style: italic;
}

.Editor ul {
	list-style-type: disc; /* Ensure bullet points are visible */
	padding-left: 20px;    /* Add indentation */
  }
  